var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "table-wrap" }, [
    _c(
      "div",
      {
        ref: "tableThead",
        staticClass: "table-head",
        style: {
          "--thead-position-top":
            typeof _vm.theadTop === "string"
              ? _vm.theadTop
              : _vm.theadTop + "px",
        },
      },
      [
        _c("div", { staticClass: "table-header" }, [_vm._t("header")], 2),
        _c("table", [
          _c(
            "thead",
            [
              _vm._t("thead", function () {
                return [_vm._m(0)]
              }),
            ],
            2
          ),
        ]),
      ]
    ),
    _c("div", { ref: "tableBody", staticClass: "table-body" }, [
      _c("table", [
        _c(
          "tbody",
          [
            _vm._t("tbody", function () {
              return [
                _vm.isLoading
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "wrapper-loading",
                          attrs: { colspan: _vm.tdLength },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("加载中 ")]
                          ),
                          _c("div", { staticClass: "loading loading1" }),
                          _c("div", { staticClass: "loading loading2" }),
                          _c("div", { staticClass: "loading loading3" }),
                          _c("div", { staticClass: "loading loading4" }),
                          _c("div", { staticClass: "loading loading5" }),
                        ]
                      ),
                    ])
                  : _c("tr", [
                      _c("td", { attrs: { colspan: _vm.tdLength } }, [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                    ]),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { ref: "scrollBar", staticClass: "scroll-bar" }, [
      _c("div", { staticStyle: { height: "1px" } }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("列表1")]),
      _c("th", [_vm._v("列表2")]),
      _c("th", [_vm._v("列表3")]),
      _c("th", [_vm._v("列表4")]),
      _c("th", [_vm._v("列表5")]),
      _c("th", [_vm._v("列表6")]),
      _c("th", [_vm._v("列表7")]),
      _c("th", [_vm._v("列表8")]),
      _c("th", [_vm._v("列表9")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }